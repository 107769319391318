var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "grid md:flex" }, [
    _c(
      "div",
      { class: "w-full sm:w-full " + `${_vm.sendButton ? "md:w-10/12" : ""}` },
      [
        _c("vue-tags-input", {
          staticClass: "overflow-visible w-full zfix",
          attrs: {
            id: "idtags",
            tags: _vm.tags,
            "autocomplete-items": _vm.autocompleteItems,
            placeholder: _vm.placeholderText,
            separators: [";", " ", "\n", ","],
            "add-only-from-autocomplete": false,
            validation: _vm.validation,
          },
          on: {
            "before-deleting-tag": _vm.beforeDelete,
            "before-adding-tag": _vm.beforeAdd,
            "tags-changed": _vm.tagsChanged,
          },
          scopedSlots: _vm._u([
            {
              key: "tag-center",
              fn: function (slot) {
                return [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip",
                          value: {
                            content: _vm.getTitleById(slot.tag.text),
                            classes: "tooltipPop",
                          },
                          expression:
                            "{\n          content: getTitleById(slot.tag.text),\n          classes: 'tooltipPop'\n        }",
                        },
                      ],
                    },
                    [_vm._v(_vm._s(slot.tag.text))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.tag,
            callback: function ($$v) {
              _vm.tag = $$v
            },
            expression: "tag",
          },
        }),
        _c("span", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.tags.length > 0 && !_vm.tagsValid,
              expression: "(tags.length > 0 && !tagsValid)",
            },
          ],
          staticClass: "text-danger text-sm",
          attrs: { hidden: !(_vm.tags.length > 0 && !_vm.tagsValid) },
        }),
      ],
      1
    ),
    _vm.sendButton
      ? _c(
          "div",
          {
            staticClass:
              "w-full sm:w-full md:w-2/12 flex justify-end sm:justify-end md:justify-start ml-0 md:ml-4 mt-2 md:mt-0",
          },
          [
            _c(
              "vs-button",
              {
                attrs: {
                  type: "filled",
                  disabled: _vm.tags.length === 0 || !_vm.tagsValid,
                },
                on: { click: _vm.handleSend },
              },
              [_vm._v(_vm._s(_vm.sendText))]
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }