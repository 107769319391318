var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    {
      class: _vm.cardClasses,
      attrs: {
        title: `${_vm.question.id} ${
          _vm.question.origin ? `- ${_vm.question.origin}` : ""
        }`,
      },
    },
    [
      _c("div", { staticClass: "flex flex-col" }, [
        _vm.question.difficulty_level
          ? _c(
              "div",
              { staticStyle: { position: "absolute", right: "25px" } },
              [
                _c("difficulty-indicator", {
                  attrs: {
                    difficulty: _vm._.get(_vm.question, "difficulty_level", ""),
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _c("div", { staticClass: "mb-4 w-full" }, [
          _c(
            "div",
            { staticClass: "flex flex-wrap gap-1" },
            _vm._l(_vm.question.tags, function (tag, i) {
              return _c(
                "div",
                {
                  key: i,
                  staticClass:
                    "flex-shrink-0 border-faint-grey border-solid border py-0 px-1 rounded text-xs",
                },
                [_vm._v(_vm._s(_vm.responsiveTruncate(tag)))]
              )
            }),
            0
          ),
        ]),
        _vm.questionData
          ? _c(
              "div",
              { staticClass: "w-full", style: _vm.contentStyle },
              [
                _c(
                  _vm.$utils.format.component.fromQuestionType(
                    _vm.questionData.type
                  ).show,
                  {
                    tag: "component",
                    staticClass: "questionDataContainer",
                    attrs: { item: _vm.questionData, showAnswerSection: false },
                  }
                ),
                _c("div", {
                  style: _vm.faderElementStyle,
                  attrs: { hidden: !_vm.collapseContent },
                }),
              ],
              1
            )
          : _vm._e(),
        _c(
          "div",
          {
            staticClass: "hover:text-primary cursor-pointer mt-2 w-full",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.expandCard.apply(null, arguments)
              },
            },
          },
          [
            _c(
              "div",
              { staticClass: "flex justify-center" },
              [
                _c("feather-icon", {
                  staticClass: "mr-1",
                  attrs: {
                    svgClasses: "w-7 h-7",
                    icon: _vm.collapseContent
                      ? "ChevronDownIcon"
                      : "ChevronUpIcon",
                  },
                }),
              ],
              1
            ),
          ]
        ),
        _c(
          "div",
          { class: _vm.actionButtonsContainerClass },
          [
            _vm.selectMode
              ? _c(
                  "vs-checkbox",
                  {
                    on: { input: _vm.selectQuestion },
                    model: {
                      value: _vm.selected,
                      callback: function ($$v) {
                        _vm.selected = $$v
                      },
                      expression: "selected",
                    },
                  },
                  [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.$t("base_question.card.action.select"))
                      ),
                    ]),
                  ]
                )
              : _vm._e(),
            _vm.canCustomize
              ? _c(
                  "vs-button",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: {
                          content: _vm.$t(
                            "base_question.card.action.customize"
                          ),
                          delay: { show: 500, hide: 100 },
                        },
                        expression:
                          "{\n        content: $t('base_question.card.action.customize'),\n        delay: { show: 500, hide: 100 }\n      }",
                      },
                    ],
                    class: _vm.actionButtonClass,
                    attrs: {
                      type: "flat",
                      "icon-pack": "feather",
                      icon: "icon-copy",
                    },
                    on: { click: _vm.customizeQuestion },
                  },
                  [_vm._v(_vm._s(_vm.textForAction("action.customize")))]
                )
              : _vm._e(),
            _vm.canAlter
              ? _c(
                  "vs-button",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: {
                          content: _vm.$t("base_question.card.action.edit"),
                          delay: { show: 500, hide: 100 },
                        },
                        expression:
                          "{\n        content: $t('base_question.card.action.edit'),\n        delay: { show: 500, hide: 100 }\n      }",
                      },
                    ],
                    class: _vm.actionButtonClass,
                    attrs: {
                      type: "flat",
                      "icon-pack": "feather",
                      icon: "icon-edit",
                      svgClasse: "",
                    },
                    on: { click: _vm.editQuestion },
                  },
                  [_vm._v(_vm._s(_vm.textForAction("action.edit")))]
                )
              : _vm._e(),
            _vm.canAlter
              ? _c(
                  "vs-button",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: {
                          content: _vm.$t("base_question.card.action.delete"),
                          delay: { show: 500, hide: 100 },
                        },
                        expression:
                          "{\n        content: $t('base_question.card.action.delete'),\n        delay: { show: 500, hide: 100 }\n      }",
                      },
                    ],
                    class: _vm.actionButtonClass,
                    attrs: {
                      color: "danger",
                      type: "flat",
                      "icon-pack": "feather",
                      icon: "icon-trash",
                    },
                    on: { click: _vm.destroyQuestion },
                  },
                  [_vm._v(_vm._s(_vm.textForAction("action.delete")))]
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }