var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "BaseQuestionGrid" },
    [
      _c("InfiniteGrid", {
        ref: "grid",
        staticClass: "infiniteGrid",
        attrs: { service: _vm.service },
        scopedSlots: _vm._u([
          {
            key: "item",
            fn: function (data) {
              return [
                _c("base-question-card", {
                  key: data.item,
                  attrs: { mode: _vm.mode, question: data.item },
                  on: {
                    action: function ($event) {
                      return _vm.$emit("questioncard-action", $event)
                    },
                  },
                }),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }